/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim2 mt--30 pb--0 pt--0" name={"uvod-3"} anim={"2"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--0 pl--0 pr--0 pt--0 flex--center" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--48 title-box--invert" style={{"maxWidth":1000}} content={"Profesionální mytí prosklených ploch"}>
              </Title>

              <Text className="text-box text-box--left text-box--invert pl--06" content={"<span style=\"font-weight: bold;\">Mytí velkých prosklených ploch<br>Mytí všech typů oken a výloh<br>Mytí hladkých plášťů budov<br>Mytí venkovních žaluzií<br>Mytí solárních panelů<br>Bělení PVC rámů<br>Mytí ve výškách</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--left fs--24 btn-box--invert mt--50" innerClassName="pb--02 pl--0 pr--0" href={"/cenik"} content={"Ceník"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" anim={null} animS={null} style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/c5766c04767541ba86b5ed2e3816ddf9_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39397/c5766c04767541ba86b5ed2e3816ddf9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/c5766c04767541ba86b5ed2e3816ddf9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/c5766c04767541ba86b5ed2e3816ddf9_s=860x_.jpg 860w"} position={{"x":"-58.63%","y":"-2.05%"}} style={{"maxWidth":1000}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"bvy71zhd48m"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --left pt--20" anim={"2"}>
              
              <Text className="text-box text-box--center" content={"<span style=\"color: var(--black); font-weight: bold;\">Jsme specialisti na mytí všech typů prosklených&nbsp;</span><span style=\"color: var(--black); font-weight: bold;\">ploch</span><span style=\"color: var(--black); font-weight: bold;\"><br></span>"}>
              </Text>

              <Title className="title-box title-box--center" content={"Precizní servis, na který si rádi zvyknete"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--black); font-weight: bold;\">Pro firmy &amp; domácnosti</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xk8wx36l57"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"informace"}>
          
          <ColumnWrap className="column__flex --shape3 js-anim  --anim2 --justify el--3 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"3"} anim={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left" anim={"2"} animS={"2"} style={{"maxWidth":410}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Mytí oken a výloh"}>
              </Title>

              <Text className="text-box" content={"Profesionální mytí všech typů oken, výloh skleněných příček, prosklených lodžií, zimních zahrad v bytových a rodinných domech, kancelářích a firmách...."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left" anim={"2"} animS={"2"} style={{"maxWidth":410}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Čištění / Bělení PVC rámů oken"}>
              </Title>

              <Text className="text-box" content={"K mytí oken navíc přinášíme i velmi účinné čištění všech plastových částí oken, parapetů, dveří a dalších ploch z tvrdého bílého PVC s dokonalým bělícím účinkem.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left" anim={"2"} animS={"2"} style={{"maxWidth":410}}>
              
              <Title className="title-box" style={{"maxWidth":300}} content={"Mytí velkých ploch"}>
              </Title>

              <Text className="text-box" content={"Velké prosklené plochy, fasády, hladké opláštění budov, autosalony, reklamní cedule, průmyslové haly, administrativní komplexy...."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" name={"nypxgtvyyw"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Mytí venkovních žaluzií"}>
              </Title>

              <Text className="text-box" content={"Profesionální čištění venkovních žaluzií.<br>&nbsp;Čištění provádíme až do výšky 17m2 kdekoliv po celé ČR.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Mytí solárních panelů"}>
              </Title>

              <Text className="text-box" content={"V důsledku nahromadění prachu a jiných atmosférických nečistot, které ulpívají na povrchu, mohou solární panely ztratit až 30 % své účinnosti Vaše panely vyčistíme bezpečně a efektivně."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Mytí ve výškách"}>
              </Title>

              <Text className="text-box" content={"S využitím speciálních teleskopických tyčí s jemnými kartáči, se dostaneme bezpečně ze země i do těžko přístupných míst ve výšce až 17 metrů!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"faankom3mn4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"appi638tqdf"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" columns={"2"} anim={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Title className="title-box" content={"Mytí demineralizovanou vodou"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":562}} content={"Ideální na mytí velkých prosklených ploch, opláštění budov....<br>Používáme profesionální mycí systémy s technologii REVERZNÍ OSMÓZY, která z vody odstraní chlor, minerály a jiné nečistoty.<br>Výsledkem je dokonale čistá voda, která po odpaření nezanechává žádné skvrny ani šmouhy.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/3c148d69c20c4cb88b279b8a4327f295_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/39397/3c148d69c20c4cb88b279b8a4327f295_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/3c148d69c20c4cb88b279b8a4327f295_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/3c148d69c20c4cb88b279b8a4327f295_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape3" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/6bf13e69770d48eea64ff9379eb1ec7a_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/39397/6bf13e69770d48eea64ff9379eb1ec7a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/6bf13e69770d48eea64ff9379eb1ec7a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/6bf13e69770d48eea64ff9379eb1ec7a_s=860x_.jpg 860w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Title className="title-box" content={"Výhody mytí demineralizovanou vodou"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":457}} content={"Antistaticky ošetřuje,&nbsp;povrch tak vydrží déle čistý<br>Povrch je po vyschnutí naprosto čistý bez šmouh<br>Bez použití horolezecké techniky, nebo plošiny<br>Bezpečně ze země do výšky až 17 metrů!<br>Levnější než klasické výškové mytí<br>Perfektní čistící účinky<br>&nbsp;Je zcela ekologická&nbsp; ­<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"1b38mty9yvk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" name={"7d9mr4ndjv"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --left" anim={"2"}>
              
              <Title className="title-box title-box--center" content={"Kontaktujte nás a uvidíte svět v novém světle!"}>
              </Title>

              <Button className="btn-box btn-box--center" content={"Kontaktovat"} href={"/kontakt"} use={"page"}>
              </Button>

              <Text className="text-box text-box--center" content={"Konkrétní specifikaci úklidových prací, vám rádi připravíme dle Vašich individuálních požadavků."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"informace-3"}>
          
          <ColumnWrap className="column__flex --shape4 js-anim  --anim2 el--2 pb--0 pl--0 pr--0 pt--0 flex--top" style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"} anim={"2"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 pb--0 pt--0" style={{"maxWidth":""}} anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39397/4c4752f029a84406a86e1daac2e66fb3_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39397/4c4752f029a84406a86e1daac2e66fb3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39397/4c4752f029a84406a86e1daac2e66fb3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39397/4c4752f029a84406a86e1daac2e66fb3_s=860x_.jpg 860w"} style={{"maxWidth":496}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--60 pt--60" anim={"4"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box" style={{"maxWidth":1000}} content={"Jakoukoliv omyvatelnou plochu dovedeme dát perfektně do pucu!<br><br>Těšíme se na spolupráci!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-custom-1)"}} anim={"2"}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Pojďte do toho s námi!"}>
              </Title>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Důsledná kontrola<br>Férové ceny<br>Flexibilita\n<br>Pečlivost<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--right" content={"<span style=\"color: var(--color-custom-1--95); font-weight: bold;\">Mytí oken a dalších prosklených ploch pro firmy i domácnosti v Praze a středočeském kraji</span>"}>
              </Text>

              <Text className="text-box text-box--invert" content={"info@mytiokencz.cz"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}